import { dispatch } from "./store";
import Api from "@helpers/api";
import { endpoints } from "@configs/endpoints";

const NAMESPACE = "archive";

export const CREATE_ARCHIVE = `${NAMESPACE}/CREATE_ARCHIVE`;
export const CREATE_ARCHIVE_FAIL = `${NAMESPACE}/CREATE_ARCHIVE_FAIL`;
export const CREATE_ARCHIVE_SUCCESS = `${NAMESPACE}/CREATE_ARCHIVE_SUCCESS`;

export const createArchive = (data, onSuccess) => {
  dispatch({ type: CREATE_ARCHIVE });
  Api({
    endpoint: endpoints.createArchiveRequest(),
    data,
    onSuccess: (response) => {
      dispatch({
        type: CREATE_ARCHIVE_SUCCESS,
        data: response.data,
      });
      onSuccess();
      alert("Request has been sent")
    },
    onFail: (error) => {
      dispatch({ type: CREATE_ARCHIVE_FAIL, error });
      alert("Sorry, request could not be sent, please try again later")
    },
  });
};

const initialState = {
  isLoading: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ARCHIVE:
      return { ...state, isLoading: true };
    case CREATE_ARCHIVE_SUCCESS:
    case CREATE_ARCHIVE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';

let store;

export const configureStore = initialState => {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const appliedMiddleware = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);
    store = initialState
        ? createStore(
            reducers,
            initialState,
            composeEnhancers(appliedMiddleware),
        )
        : createStore(reducers, composeEnhancers(appliedMiddleware));

    return store;
}
export default store;
const getStore = () => store;
export const getState = () => getStore() && getStore().getState();
export const dispatch = data => getStore() && getStore().dispatch(data);

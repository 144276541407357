import { dispatch } from "./store";
import Api from "@helpers/api";
import { endpoints } from "@configs/endpoints";

const NAMESPACE = "inspection";

export const CREATE_INSPECTION = `${NAMESPACE}/CREATE_INSPECTION`;
export const CREATE_INSPECTION_FAIL = `${NAMESPACE}/CREATE_INSPECTION_FAIL`;
export const CREATE_INSPECTION_SUCCESS = `${NAMESPACE}/CREATE_INSPECTION_SUCCESS`;

const GET_INSPECTION = `${NAMESPACE}/GET_INSPECTION`;
const GET_INSPECTION_FAIL = `${NAMESPACE}/GET_INSPECTION_FAIL`;
const GET_INSPECTION_SUCCESS = `${NAMESPACE}/GET_INSPECTION_SUCCESS`;

export const createInspection = (data, onSuccess) => {
  dispatch({ type: GET_INSPECTION });

  Api({
    endpoint: endpoints.createInspection(data.asset_id),
    data,
    onSuccess: response => {
      dispatch({
        type: CREATE_INSPECTION_SUCCESS,
        data: response.data,
      });
      !!onSuccess && onSuccess(response.data.id);
    },
    onFail: error => {
      dispatch({ type: CREATE_INSPECTION_FAIL, error });
    }
  });
};

export const getInspection = (inspection_id) => {
  dispatch({ type: GET_INSPECTION });

  Api({
    endpoint: endpoints.getInspection(inspection_id),
    onSuccess: response => {
      dispatch({
        type: GET_INSPECTION_SUCCESS,
        data: response.data,
      });
    },
    onFail: error => dispatch({ type: GET_INSPECTION_FAIL, error })
  });
};

const initialState = {
  isLoading: false,
  data: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_INSPECTION:
      return { ...state, isLoading: true };
    case CREATE_INSPECTION_SUCCESS:
    case GET_INSPECTION_SUCCESS:
      return { ...state, isLoading: false, data: action.data };
    case CREATE_INSPECTION_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

import { combineReducers } from "redux";
import auth from "./auth";
import companies from "./companies";
import notification from "./notification";
import annotation from "./annotation";
import realityMesh from "./realityMesh";
import assets from "./assets";
import pointCloud from "./pointCloud";
import documentCategories from "./documentCategories";
import inspection from "./inspection";
import reports from "./reports";
import statistics from "./statistics";
import activityLogs from "./activityLogs";
import archive from "./archive";
import annotationTypes from "./annotationTypes";
import dashcams from "./dashcams";
import general from "./general";
import firebase from "./firebase";

export default combineReducers({
  auth,
  companies,
  notification,
  annotation,
  archive,
  realityMesh,
  assets,
  activityLogs,
  reports,
  statistics,
  pointCloud,
  documentCategories,
  inspection,
  annotationTypes,
  dashcams,
  general,
  firebase,
});

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import { Provider } from "react-redux";
import { configureStore } from "@redux/store";
import { ToastProvider } from "react-toast-notifications";
import './index.css';

function removeConsole() {}

if (process.env.NODE_ENV !== "development") {
  console.log = removeConsole;
  console.warn = removeConsole;
  console.error = removeConsole;

  Sentry.init({
    dsn: "https://b6fa9f9281804958aed8afc3d92938a8@o406540.ingest.sentry.io/5278247",
  });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <ToastProvider placement="bottom-right">
      <App />
    </ToastProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import { dispatch } from "./store";
import Api from "@helpers/api";
import { endpoints } from "@configs/endpoints";

const NAMESPACE = "reality-mesh";

export const CLEAR_REALITY_MESHES = `${NAMESPACE}/CLEAR_REALITY_MESHES`;
export const API_GET_REALITY_MESHES = `${NAMESPACE}/API_GET_REALITY_MESHES`;
export const API_GET_REALITY_MESHES_FAIL = `${NAMESPACE}/API_GET_REALITY_MESHES_FAIL`;
export const API_GET_REALITY_MESHES_SUCCESS = `${NAMESPACE}/API_GET_REALITY_MESHES_SUCCESS`;

export const clearRealityMeshes = () =>
  dispatch({ type: CLEAR_REALITY_MESHES });

export const apiGetRealityMeshes = assetId => {
  dispatch({ type: API_GET_REALITY_MESHES });
  Api({
    endpoint: endpoints.getRealityMeshesForAsset(assetId),
    onSuccess: response => {
      dispatch({ type: API_GET_REALITY_MESHES_SUCCESS, data: response.data });
    },
    onFail: error => {
      dispatch({ type: API_GET_REALITY_MESHES_FAIL, error });
    }
  });
};
const initialState = {
  isLoading: false,
  data: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_REALITY_MESHES:
      return { ...state, isLoading: false, data: [] };
    case API_GET_REALITY_MESHES:
      return { ...state, isLoading: true };
    case API_GET_REALITY_MESHES_SUCCESS:
      return { ...state, isLoading: false, ...action.data };
    case API_GET_REALITY_MESHES_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

import { dispatch } from "./store";

const NAMESPACE = "general";
export const TOGGLE_DEV = `${NAMESPACE}/TOGGLE_DEV`;

export const toggleDev = (flag = null) => dispatch({ type: TOGGLE_DEV, flag });
const initialState = {
    isDev: process.env.NODE_ENV == "development",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_DEV:
            return { ...state, isDev: action.flag == null ? !state.isDev : action.flag }
        default:
            return state;
    }
};

import { dispatch } from "./store";
import Api from "@helpers/api";
import { endpoints } from "@configs/endpoints";

const NAMESPACE = "statistics";

const GET_STATISTICS = `${NAMESPACE}/GET_STATISTICS`;
const GET_STATISTICS_FAIL = `${NAMESPACE}/GET_STATISTICS_FAIL`;
const GET_STATISTICS_SUCCESS = `${NAMESPACE}/GET_STATISTICS_SUCCESS`;

export const getStatistics = (data) => {
    dispatch({
        type: GET_STATISTICS
    });

    Api({
        endpoint: endpoints.getStatistics(),
        data,
        onSuccess: response => {
            dispatch({
                type: GET_STATISTICS_SUCCESS,
                data: response.data,
            });
        },
        onFail: error => dispatch({
            type: GET_STATISTICS_FAIL,
            error
        })
    });
};

const initialState = {
    isLoading: false,
    data: [],
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_STATISTICS_SUCCESS:
            return {
                ...state, isLoading: false, data: action.data
            };
        case GET_STATISTICS:
            return {
                ...state, isLoading: true, error: null
            };
        case GET_STATISTICS_FAIL:
            return {
                ...state, isLoading: false, error: action.error
            };
        default:
            return state;
    }
};

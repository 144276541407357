import { dispatch } from "./store";
import Api from "@helpers/api";
import { endpoints } from "@configs/endpoints";

const NAMESPACE = "point-clouds";

export const CLEAR_POINT_CLOUDS = `${NAMESPACE}/CLEAR_POINT_CLOUDS`;
export const API_GET_POINT_CLOUDS = `${NAMESPACE}/API_GET_POINT_CLOUDS`;
export const API_GET_POINT_CLOUDS_FAIL = `${NAMESPACE}/API_GET_POINT_CLOUDS_FAIL`;
export const API_GET_POINT_CLOUDS_SUCCESS = `${NAMESPACE}/API_GET_POINT_CLOUDS_SUCCESS`;

export const clearPointClouds = () => dispatch({ type: CLEAR_POINT_CLOUDS });

export const apiGetPointClouds = assetId => {
  dispatch({ type: API_GET_POINT_CLOUDS });
  Api({
    endpoint: endpoints.getPointCloudsForAsset(assetId),
    onSuccess: response => {
      dispatch({ type: API_GET_POINT_CLOUDS_SUCCESS, data: response.data });
    },
    onFail: error => {
      dispatch({ type: API_GET_POINT_CLOUDS_FAIL, error });
    }
  });
};
const initialState = {
  isLoading: false,
  data: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_POINT_CLOUDS:
      return { ...state, isLoading: false, data: [] };
    case API_GET_POINT_CLOUDS:
      return { ...state, isLoading: true };
    case API_GET_POINT_CLOUDS_SUCCESS:
      return { ...state, isLoading: false, ...action.data };
    case API_GET_POINT_CLOUDS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

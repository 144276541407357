import { dispatch, getState } from "./store";
import Api from "@helpers/api";
import { endpoints } from "@configs/endpoints";
import _ from 'lodash';

const NAMESPACE = "activity-logs";

const GET_ACTIVITY_LOGS = `${NAMESPACE}/GET_ACTIVITY_LOGS`;
const GET_ACTIVITY_LOGS_FAIL = `${NAMESPACE}/GET_ACTIVITY_LOGS_FAIL`;
const GET_ACTIVITY_LOGS_SUCCESS = `${NAMESPACE}/GET_ACTIVITY_LOGS_SUCCESS`;

export const getActivityLogs = (isNext) => {
  if (isNext && getState().activityLogs.data.next_page_url == null) {
    return;
  }
  if (getState().activityLogs.isLoading) {
    return;
  }
  let page = 1;
  if (isNext) {
    page = getState().activityLogs.data.current_page + 1;
  }

    dispatch({ type: GET_ACTIVITY_LOGS });

    Api({
        endpoint: endpoints.getActivityLogs(),
        data: { page },
        onSuccess: response => {
            dispatch({
                type: GET_ACTIVITY_LOGS_SUCCESS,
                data: response.data,
                isNext,
            });
        },
        onFail: error => dispatch({ type: GET_ACTIVITY_LOGS_FAIL, error })
    });
};

const initialState = {
  isLoading: false,
  data: { data: [] },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTIVITY_LOGS_SUCCESS:
            {
                if (!action.isNext) {
                    return { ...state, isLoading: false, data: action.data };
                }

                const oldIds = _.map(state.data, 'id')
                const newLogs = action.data.data.filter(item => !_.includes(oldIds, item.id));
                const data = action.data
                data.data = [...state.data.data, ...newLogs]

      return {
        ...state,
        isLoading: false,
        data,
      };
    }
    case GET_ACTIVITY_LOGS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ACTIVITY_LOGS_FAIL:
      return {
        ...state,
        isLoading: false,
        data: action.error,
      };
    default:
      return state;
  }
};

const NAMESPACE = "firebase";

const initialState = {
  isLoading: true,
  data: [],
  type: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NAMESPACE + "/SET_FIREBASE_ASSETS":
      return { ...state, data: action.data, type: "assets" };
    case NAMESPACE + "/SET_FIREBASE_USERS":
      return { ...state, data: action.data, type: "users" };
    default:
      return state;
  }
};

export const URL = process.env.REACT_APP_ENDPOINT_URL;
export const CLIENT_SECRET = "sgHOG2NyyVECAiam7JS4QBJVPMhzrQ6eFh3SVSFa";
export const CLIENT_ID = 1;

export const endpoints = {
  getToken: () => ["POST", URL + "login"],
  registerAccount: () => ["POST", URL + "signup"],
  getProfile: () => ["GET", URL + "api/profile"],
  getNotification: () => ["GET", URL + "api/notification"],
  updateAnnotation: (id) => ["POST", URL + "api/annotations/" + id],
  getAnnotation: () => ["GET", URL + "api/annotations"],
  getAssetSummary: (id) => ["GET", URL + "api/asset-summary/" + id],
  getRemoteConfig: () => ["GET", URL + "api/remote-config"],
  updateRemoteConfig: () => ["POST", URL + "api/remote-config/update"],
  getStatistics: () => ["GET", URL + "api/statistics"],
  getPointCloudsForAsset: (assetId) => ["GET", URL + `api/assets/${assetId}/point-clouds`],
  getRealityMeshesForAsset: (assetId) => ["GET", URL + `api/assets/${assetId}/reality-meshes`],
  createInspection: (assetId) => ["POST", URL + `api/assets/${assetId}/aerial-inspections`],
  createReport: () => ["POST", URL + `api/report`],
  deleteReport: (id) => ["DELETE", URL + "api/report/" + id],
  deleteAnnotation: (id) => ["DELETE", URL + "api/annotations/" + id],
  createArchiveRequest: () => ["POST", URL + `api/archive`],
  uploadInspectionMedia: (inspectionId) => ["POST", URL + `api/aerial-inspections/${inspectionId}/inspection-medias`],
  getInspection: (inspectionId) => ["GET", URL + `api/aerial-inspections/${inspectionId}`],
  getReports: () => ["GET", URL + "api/report"],
  getActivityLogs: () => ["GET", URL + "api/activity-log"],
  updateInspectionMedia: (mediaId) => ["POST", URL + `api/inspection-medias/${mediaId}`],
  getUsers: () => ["GET", URL + `api/user`],
  addUser: () => ["POST", URL + `api/user`],
  updateUser: (user_id) => ["POST", URL + `api/user/${user_id}/update`],
  updateUserAccessDetections: (user_id) => ["POST", URL + `api/user/${user_id}/update_access_annotation`],
  restoreAnnotation: (id) => ["POST", URL + `api/annotations/restore?annotation_ids=${id}`],
  deleteAnnotationAction: (annotationId, actionId) => ["DELETE", URL + `api/annotations/${annotationId}/actions/${actionId}`],
  addNewBindedDashcam: () => ["POST", URL + "api/dashcams"],
  unbindDashcam: (id) => ["DELETE", URL + `api/dashcams/${id}`],
  getOrder: () => ["GET", URL + `api/order`],
  updateCompany: (id) => ["PUT", URL + `api/company/${id}`],
  getAssetEvent: (id) => ["GET", URL + `api/assetevents/${id}`],
};

import { dispatch, getState } from "./store";
import Api from "@helpers/api";
import { endpoints } from "@configs/endpoints";

const NAMESPACE = "reports";

const GET_REPORTS = `${NAMESPACE}/GET_REPORTS`;
const GET_REPORTS_FAIL = `${NAMESPACE}/GET_REPORTS_FAIL`;
const GET_REPORTS_SUCCESS = `${NAMESPACE}/GET_REPORTS_SUCCESS`;

export const CREATE_REPORT = `${NAMESPACE}/CREATE_REPORT`;
export const CREATE_REPORT_FAIL = `${NAMESPACE}/CREATE_REPORT_FAIL`;
export const CREATE_REPORT_SUCCESS = `${NAMESPACE}/CREATE_REPORT_SUCCESS`;

export const DELETE_REPORT = `${NAMESPACE}/DELETE_REPORT`;
export const DELETE_REPORT_FAIL = `${NAMESPACE}/DELETE_REPORT_FAIL`;
export const DELETE_REPORT_SUCCESS = `${NAMESPACE}/DELETE_REPORT_SUCCESS`;

export const createReport = ({ asset_id, from, to, onSuccess = () => null }) => {
    dispatch({ type: CREATE_REPORT });
    const data = { asset_id, from, to };
    Api({
        endpoint: endpoints.createReport(),
        data,
        onSuccess: r => {
            dispatch({ type: CREATE_REPORT_SUCCESS, data: r.data });
            onSuccess(r.data.id);
        },
        onFail: error => dispatch({ type: CREATE_REPORT_FAIL, error }),
    });
};
export const deleteReport = (id, asset_id, onSuccess) => {
    dispatch({ type: DELETE_REPORT });

    Api({
        endpoint: endpoints.deleteReport(id),
        onSuccess: (response) => {
            dispatch({ type: DELETE_REPORT_SUCCESS, data: response.data });
            !!onSuccess && onSuccess();
            alert("Report has successfully been deleted");
            getReports(asset_id, getState().reports.data.current_page);
        },
        onFail: (error) => {
            dispatch({ type: DELETE_REPORT_FAIL, error });
            alert("Your report could not be deleted, please try again later")
        },
    });
};

export const getReports = (asset_id, page = 1) => {
    dispatch({ type: GET_REPORTS });

    Api({
        endpoint: endpoints.getReports(),
        data: { asset_ids: asset_id, page },
        onSuccess: r => dispatch({ type: GET_REPORTS_SUCCESS, data: r.data }),
        onFail: error => dispatch({ type: GET_REPORTS_FAIL, error }),
    });
};

const initialState = {
    isLoading: false,
    data: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case DELETE_REPORT:
        case GET_REPORTS:
        case CREATE_REPORT:
            return { ...state, isLoading: true };
        case CREATE_REPORT_SUCCESS:
        case DELETE_REPORT_FAIL:
        case DELETE_REPORT_SUCCESS:
            return { ...state, isLoading: false };
        case GET_REPORTS_SUCCESS:
            return { ...state, isLoading: false, data: action.data };
        case GET_REPORTS_FAIL:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
};

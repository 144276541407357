import { dispatch } from "./store";
import Api from "@helpers/api";
import { endpoints } from "@configs/endpoints";

const NAMESPACE = "notification";

export const CREATE_NOTIFICATION = `${NAMESPACE}/CREATE_NOTIFICATION`;
export const CREATE_NOTIFICATION_FAIL = `${NAMESPACE}/CREATE_NOTIFICATION_FAIL`;
export const CREATE_NOTIFICATION_SUCCESS = `${NAMESPACE}/CREATE_NOTIFICATION_SUCCESS`;

const GET_NOTIFICATION = `${NAMESPACE}/GET_NOTIFICATION`;
const GET_NOTIFICATION_FAIL = `${NAMESPACE}/GET_NOTIFICATION_FAIL`;
const GET_NOTIFICATION_SUCCESS = `${NAMESPACE}/GET_NOTIFICATION_SUCCESS`;

export const createNotification = (data, onSuccess) => {
  dispatch({ type: GET_NOTIFICATION });

  Api({
    endpoint: endpoints.createNotification(data.asset_id),
    data,
    onSuccess: (response) => {
      dispatch({
        type: CREATE_NOTIFICATION_SUCCESS,
        data: response.data,
      });
      !!onSuccess && onSuccess(response.data.id);
    },
    onFail: (error) => {
      dispatch({ type: CREATE_NOTIFICATION_FAIL, error });
    },
  });
};

export const getNotification = () => {
  dispatch({ type: GET_NOTIFICATION });

  Api({
    endpoint: endpoints.getNotification(),
    onSuccess: (response) => {
      dispatch({
        type: GET_NOTIFICATION_SUCCESS,
        data: response.data,
      });
    },
    onFail: (error) => dispatch({ type: GET_NOTIFICATION_FAIL, error }),
  });
};

const initialState = {
  isLoading: false,
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      return { ...state, isLoading: true };
    case CREATE_NOTIFICATION_SUCCESS:
    case GET_NOTIFICATION_SUCCESS:
      return { ...state, isLoading: false, data: action.data };
    case GET_NOTIFICATION_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
